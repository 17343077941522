import React, {useEffect, useRef, useState} from 'react';
import {FiCalendar, FiHelpCircle} from "react-icons/fi";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import './datepicker-style.css';
import {useTranslation} from "react-i18next";
import {enGB, cs, de, sk} from 'date-fns/locale'
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {parse, format, parseISO, formatISO} from 'date-fns';
import EnigooToolTip from "./EnigooToolTip";

registerLocale("en", enGB)
registerLocale("cs", cs)
registerLocale("de", de)
registerLocale("sk", sk)


export const EnigooDateTimeFieldv2 = ({
                                          label,
                                          showTimeSelect = true,
                                          onlyTime = false,
                                          inline = false,
                                          name,
                                          outsideClassName = "w-full",
                                          type = "",
                                          setActualDate = null,
                                          onKeyDown = null,
                                          toolTip = null,
                                          toolTipPosition = "right"
                                      }) => {
    let [actualValue, setActualValue] = useState(null);

    const {t, i18n} = useTranslation();

    let locale = React.useMemo(() => {
        switch (i18n.language) {
            case "en":
                return "en";
            case "de":
                return "de";
            case "sk":
                return "sk";
            case "cs":
            default:
                return "cs";
        }
    }, [i18n.language])

    let inputRef = useRef(null);

    const formatDateToISO = (date) => {
        if (date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Měsíce jsou indexovány od 0
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            if (onlyTime) {
                return `${hours}:${minutes}`
            } else if (!showTimeSelect) {
                return `${year}-${month}-${day}`
            } else {
                return `${year}-${month}-${day}T${hours}:${minutes}`
            }

        } else {
            return undefined;
        }

    }

    const parseDateFromISO = (value) => {
        if(value){
            if(onlyTime) {
                let time = value.split(":");
                if(time.length===2){
                    let h = time[0];
                    let m = time[1];
                    let date = new Date();
                    date.setHours(h);
                    date.setMinutes(m);

                    return date;
                }
                return null;
            }else if(!showTimeSelect) {
                let dateArray = value.split("-");
                if(dateArray.length === 3){
                    let date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setFullYear(dateArray[0]);
                    date.setMonth(parseInt(dateArray[1])-1);
                    date.setDate(dateArray[2]);

                    return date;
                }
                return null;


            }else {
                return new Date(value);
            }
        }else{
            return null;
        }

    }

    const parseDate = (value) => {
        return value ? parseDateFromISO(value):null;
    }

    const formatDate = (date) =>{
        return date ? formatDateToISO(date):null;
    }

    const dateFormat = React.useMemo(() => {
        if (onlyTime) {
            return "HH:mm";
        } else if (showTimeSelect) {
            return "dd. MM. yyyy HH:mm"
        } else {
            return "dd. MM. yyyy";
        }
    }, [onlyTime, showTimeSelect])

    useEffect(()=>{
        setDateTime(inputRef.current)
    },[inputRef.current?.value,actualValue,onlyTime])

    const setDateTime = (input)=>{
        if (input.value && !(input.value instanceof Date) && actualValue === null) {
            if (onlyTime) {
                const now = new Date();
                const dateString = `${format(now, 'yyyy-MM-dd')} ${input.value}`;

                // Parsování řetězce na datum
                setActualValue(parse(dateString, 'yyyy-MM-dd HH:mm', new Date()));
            } else {
                setActualValue(new Date(input.value))
            }
        }
    }

    return (
        <div className={outsideClassName}>
            <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                <Field name={name}>

                    {({input, meta}) => {
                        inputRef.current = input;
                        return (
                            <>
                                {label !== "" && <div className={"form-label flex flex-row"}>{label}
                                    {toolTip && <><FiHelpCircle data-tip data-for={"_tooltip_" + name} key={name}
                                                                className={"ml-2 text-red-900"} size={18}/>
                                        <EnigooToolTip content={toolTip} id={"_tooltip_" + name}
                                                       place={toolTipPosition}/></>}
                                </div>}

                                <DatePicker onKeyDown={onKeyDown} icon={<FiCalendar/>} timeCaption={t("basics.time")}
                                            isClearable
                                            className={meta.error && meta.touched ? "form-input form-input-invalid" : "form-input"}
                                            showTimeSelect={showTimeSelect} showTimeSelectOnly={onlyTime}
                                            onChange={(newValue) => {
                                                const formatedDate = formatDate(newValue);
                                                input.onChange(formatedDate);
                                                setActualValue(newValue);
                                            }} selected={parseDate(input.value)}
                                            dateFormat={dateFormat} locale={locale}
                                />
                                {meta.error && meta.touched && <div className="form-error">{meta.error}</div>}
                            </>
                        )
                    }}
                </Field>
            </div>
        </div>
    )
}
EnigooDateTimeFieldv2.propTypes = {
    label: PropTypes.string.isRequired,
    showTimeSelect: PropTypes.bool,
    onlyTime: PropTypes.bool,
    inline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    outsideClassName: PropTypes.string,
    type: PropTypes.oneOf(["to", "from", ""]),
    setActualDate: PropTypes.func,
    onKeyDown: PropTypes.func,
    toolTip: PropTypes.string,
}
